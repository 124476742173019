/** @jsx jsx */
import { jsx, Text } from 'theme-ui';
import { Link } from 'gatsby';
import IconArrowLink from './icon/arrow-link';
import { containerMarginX } from './const';

export default ({ className, title, subtitle, subtitleUrl }) => (
  <div className={className} sx={{ mx: containerMarginX }}>
    <Text variant="title">{title}</Text>
    {subtitle && subtitleUrl && (
      <Link to={subtitleUrl}>
        <Text
          variant="subtitle"
          sx={{
            mt: [6, 16, 18],
            mx: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span>{subtitle}</span>
          <IconArrowLink sx={{ ml: 4, width: [12, 18], height: [12, 18] }} />
        </Text>
      </Link>
    )}
  </div>
);
