import React from 'react';
export default ({ className }) => (
  <svg
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.96456 2.76742L9.50016 2.5785L9.32047 8.0898L8.72079 8.10935L8.88089 3.19865L3.94593 3.36707L3.96456 2.76742Z"
      fill="#2B2C2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.28906 9.34399L8.78906 2.84399L9.21333 3.26826L2.71333 9.76826L2.28906 9.34399Z"
      fill="#2B2C2A"
    />
  </svg>
);
