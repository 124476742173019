/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql, navigate } from 'gatsby';
import { isKoreanBrowser, extractPostNodes } from '../lib/helpers';
import PostPreviewList from '../components/post-preview-list';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import Layout from '../components/layout';
import NavBar from '../components/nav-bar';
import Footer from '../components/footer';
import Titles from '../components/titles';
import { navBarHeightsInArray } from '../components/const';
import { useEffect } from 'react';

export const query = graphql`
  {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      keywords
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        hasRecipe: { eq: true }
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          isKorean
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          description
          slug {
            current
          }
        }
      }
    }
    manualPosts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        id: {
          in: [
            "5d9f97fb-6826-547b-bdd9-e73cdc24dada"
            "042b503a-27a1-52be-a016-3f9e5ff3f1e2"
          ]
        }
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          isKorean
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          description
          slug {
            current
          }
        }
      }
    }
  }
`;

const Main = props => {
  const { data, path, errors, pageContext } = props;
  const { lang } = pageContext;
  useEffect(() => {
    if (path === '/' && isKoreanBrowser()) {
      navigate('/ko', { replace: true });
    }
  }, [path]);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const normalPostNodes = extractPostNodes((data || {}).posts, lang);
  const manualPostNodes = extractPostNodes((data || {}).manualPosts, lang);
  const postNodes = [...normalPostNodes, ...manualPostNodes].sort(
    (a, b) =>
      new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
  );
  const koUrl = '/recipes/ko';
  const enUrl = '/recipes/en';

  return (
    <Layout lang={lang}>
      <SEO
        title={site.title}
        description={null}
        keywords={site.keywords}
        path={path}
        lang={lang}
        image={postNodes[0].mainImage}
      />
      <NavBar lang={lang} koUrl={koUrl} enUrl={enUrl} />
      <Titles
        sx={{ mt: navBarHeightsInArray, pt: [96, 136, 160] }}
        title="Recipes"
      />
      <PostPreviewList
        lang={lang}
        nodes={postNodes}
        sx={{ mt: [42, 58, 60] }}
      />
      <Footer
        sx={{ mt: [112, 160, 180] }}
        lang={lang}
        koUrl={koUrl}
        enUrl={enUrl}
      />
    </Layout>
  );
};

export default Main;
