/** @jsx jsx */
import { jsx, Text } from 'theme-ui';
import { Link } from 'gatsby';
import { getBlogUrl } from '../lib/helpers';
import Figure from './Figure';
import ArrowRightIcon from './icon/arrow-right';
import ResponsiveMainImage from './responsive-main-image';
import { useMedia } from '../hooks';
import { containerMarginX } from './const';

const getAspectRatioForMainImage = ({ isMobile, isTablet, isDesktop }) => {
  if (isMobile) {
    return 1;
  } else if (isTablet) {
    return 480 / 640;
  } else if (isDesktop) {
    return 480 / 1280;
  } else {
    throw new Error(`unknown device size`);
  }
};

const FeaturedPreview = ({ className, lang, node }) => {
  const { isMobile, isTablet, isDesktop } = useMedia();
  const aspectRatio = getAspectRatioForMainImage({
    isMobile,
    isTablet,
    isDesktop,
  });
  return (
    <div className={className}>
      <Link
        to={getBlogUrl(node.isKorean, node.slug.current)}
        sx={{
          textDecoration: 'none',
          color: 'black',
        }}
      >
        <ResponsiveMainImage node={node.mainImage} aspectRatio={aspectRatio} />
        <div sx={{ mx: containerMarginX }}>
          <Text
            variant="postListTitle"
            sx={{
              mt: [18, 22, 30],
              fontSize: [18, 22, 24],
              fontWeight: 'medium',
              lineHeight: ['1.55em', '1.63em', '1.58em'],
            }}
          >
            {node.title}
          </Text>
          <Text variant="postListTitle" sx={{ mt: [18, 22, 24] }}>
            {node.description}
          </Text>
          <Text
            variant="readThisArticle"
            sx={{ mt: [14, 14, 16], display: 'flex', alignItems: 'center' }}
          >
            <span>{lang === 'ko' ? '이 글 읽기' : 'Read the post'}</span>
            <ArrowRightIcon
              sx={{ width: '1em', height: '1em', ml: [6, 6, 8] }}
            />
          </Text>
        </div>
      </Link>
    </div>
  );
};

const NormalPreview = ({ className, node }) => {
  return (
    <div className={className}>
      <Link
        to={getBlogUrl(node.isKorean, node.slug.current)}
        sx={{
          textDecoration: 'none',
          color: 'black',
        }}
      >
        <Figure aspectRatio={1} node={node.mainImage} />
        <Text variant="postListTitle" sx={{ mt: [14, 16] }}>
          {node.title}
        </Text>
      </Link>
    </div>
  );
};

function PostPreviewList({ lang, nodes, className }) {
  return (
    <div className={className}>
      <FeaturedPreview
        lang={lang}
        node={nodes[0]}
        sx={{ mb: [70, 128, 128] }}
      />

      <div
        sx={{
          mx: containerMarginX,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
          columnGap: [0, 0, 32],
          gridColumnGap: [0, 0, 32],
          rowGap: [56, 96, 90],
          gridRowGap: [56, 96, 90],
        }}
      >
        {nodes.slice(1).map(node => (
          <NormalPreview node={node} key={node.id} />
        ))}
      </div>
    </div>
  );
}

export default PostPreviewList;
